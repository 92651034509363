import AccessControl from "common/components/AccessControl/AccessControl";
import ButtonAccessControl from "common/components/ButtonAccessControl/ButtonAccessControl";
import ButtonList from "common/components/ButtonList/ButtonList";
import {
    changeLoadingState, confirmChangeRoute, disableInvoiceAmount, dismissReset, dismissTargetUrl, setBeneficiaryCert,
    setMasterDoc, setMasterDocState, setMasterDocSubmitType, setPackingList, showMasterDocMessage, showSaveAs
} from "common/redux/actions";
import DocImageService from 'common/services/doc-image.service';
import docPrepService from "common/services/doc-prep.service";
import masterDocService from "common/services/masterDoc.service";
import ModuleService from 'common/services/module.service';
import PrintService from 'common/services/print.service';
import UserService from 'common/services/user.service';
import { getCurrentTab } from "common/utils/AppUtil";
import { DOC_STATUS, PRINT_CMD, WOLF_SCAN_FIELDS, TABS } from 'common/utils/Constants';
import { wolfScan } from 'common/utils/FieldValidation';
import { isBlank } from "common/utils/StringUtils";
import Confirm from 'common/views/Popup/Confirm';
import DeleteDocument from 'common/views/Popup/DeleteDocument';
import DocumentImage from 'common/views/Popup/DocumentImage';
import HSBCApproval from 'common/views/Popup/HSBCApproval';
import Message from 'common/views/Popup/Message';
import Msg from 'common/views/Popup/Msg';
import MultiChoiceMessage from "common/views/Popup/MultiChoiceMessage";
import Notice from 'common/views/Popup/Notice';
import OpenDocument from "common/views/Popup/OpenDocument";
import PrintDocument from "common/views/Popup/PrintDocument";
import SaveAs from 'common/views/Popup/SaveAs';
import SubmitMasterDoc from 'common/views/Popup/SubmitMasterDoc';
import WolfMsg from 'common/views/Popup/WolfMsg';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { submit } from "redux-form";
import styled from "styled-components";
import { fixActionBarBottom, initActionBarPosition, fixBottom } from "common/utils/UserInterfaceUtil";

const Container = styled.div`
    position: fixed;     
    width: 1202px;
    height: 40px;
    padding: 20px 24px;
    background: white;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    transition: all 200ms;
    z-index: 100;
`;

const RightBtn = styled.div`
    float: right;
    padding-left: 10px;
`;


class ActionBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openDocument: false,
            printDocument: false,
            showUpdateStatus: false,
            isReset: props.isReset,
            targetUrl: props.targetUrl,
            openSubmit: false,
            printCmd: '',
            resetMessageShowForDel: false,
            messageContent: '',
            docStatus: props.docStatus || 'isNew',
            openNotice: false,
            msg: "",
            title: "",
            imageData: [],
            showSaveAs: false,
            openWolfMsg: false,
        }
        this.saveAs = this.saveAs.bind(this);
        this.user = UserService.getCurrentUser();
        this.userPermissions = {
            approvalright: this.user.approvalright,
            wolfexceptionright: this.user.wolfexceptionright,
            submitright: this.user.clientRight.submitright,
            isCompanyEDS: this.user.isCompanyEDS,
            editright: this.user.editright
        }

    }


    componentDidMount(){
        initActionBarPosition();
        fixActionBarBottom();
        this.props.setMasterDocState(this.state.docStatus || 'isNew');

        window.addEventListener('scroll', fixBottom);
        window.addEventListener('resize', fixBottom);
    }

    componentDidUpdate(prevProps) {
        const mdocid = this.props.form?.values?.mdocid;

        if(mdocid && (prevProps.form?.values?.mdocid !== mdocid || prevProps.docStatus !== this.props.docStatus)){
            this.getPrintChoice(mdocid);

            if(ModuleService.isLFEDS()){
                DocImageService.getFiles(mdocid).then(res =>{
                    if(res && res.list){
                      this.setState({
                        imageData: res.list,
                      });
                    }
                });
            }
        }
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', fixBottom);
        window.removeEventListener('resize', fixBottom);
    }

    static getDerivedStateFromProps (props, prevState) {
        if (props.isReset !== prevState.isReset || props.targetUrl !== prevState.targetUrl || props.masterDocState !== prevState.masterDocState
                || props.showSaveAs !== prevState.showSaveAs) {
          return {
            isReset: props.isReset,
            targetUrl: props.targetUrl,
            docStatus: props.masterDocState,
            open: props.showSaveAs,
            showSaveAs: props.showSaveAs
          };
        }
        return null;
    }

    getPrintChoice(mdocid) {
        PrintService.getPrintchoice(ModuleService.hasLFEDSModule(), mdocid, this.props.form?.values?.mdoctype).then(res => {
            if (res && res.list) {
                const printChoice = res.list[0];
                masterDocService.chkLFCompanyEDS().then(response => {
                    let isLFEDS = false;
                    if (response) {
                        isLFEDS = response;
                    }
                    printChoice.isLFEDS = isLFEDS;

                    this.setState({
                        printChoice,
                        isLFEDS,
                    });
                });
            }
        });
    }

    toggleOpenSubmit = () => {
        this.setState(prevState => ({
            openSubmit: !prevState.openSubmit
        }));
    }

    approve = () => {
        const errorMsg = wolfScan(this.props.form.values, WOLF_SCAN_FIELDS);
        if(errorMsg && errorMsg.length>0){
            this.setState({
                openWolfMsg: true,
                wolfMsg: errorMsg,
            })
        }else{
            this.setState({
                title: 'Approve',
                showUpdateStatus: true,
                status: DOC_STATUS.APPROVED,
                statusMsg: 'Do you wish to approve the current record now?',
            })
        }
    }

    unlock = () => {
        this.setState({
            showUpdateStatus: true,
            status: DOC_STATUS.UNLOCKED,
            statusMsg: 'Do you wish to unlock the current record now?',
        })
    }

    submitDoc = (statusHistory) => {
        const masDoc = this.props.form.values;
        if(statusHistory){
            masDoc.mdstatushistorylist.unshift(statusHistory);
        }
        this.updateStatus(DOC_STATUS.SUBMITTED, masDoc);
    }

    checkStatus = (docStatus, masDoc) => {
        masterDocService.approveUnlockMasterDocScanCheck(docStatus, masDoc).then(res => {
            if (res) {
                if (res.checkResult && res.checkResult.resultMsgReturnToUser) {
                    this.setState({
                        openNotice: true,
                        msg: res.checkResult.resultMsg,
                    });
                } else if (res.docStatus === docStatus) {
                    this.updateStatus(docStatus, masDoc);
                } else {
                    let failedMsg = '';
                    if (docStatus === DOC_STATUS.APPROVED) {
                        failedMsg = 'Approve Master Document Status Failure.';
                    } else if (docStatus === DOC_STATUS.UNLOCKED) {
                        failedMsg = 'Unlock Master Document Status Failure.';
                    } else if (docStatus === DOC_STATUS.SUBMITTED) {
                        failedMsg = 'Submit Master Document Status Failure.';
                    }
                    this.setState({
                        openNotice: true,
                        msg: failedMsg,
                    });
                }
            }
        });
    }

    checkAndUpdateStatus = (docStatus) => {
        const masDoc = this.props.form.values;

        if(docStatus === DOC_STATUS.UNLOCKED){
            this.updateStatus(docStatus, masDoc);
        }else{
            this.checkStatus(docStatus, masDoc);
        }

    }

    reload = () => {
        const metaData = {mdocid: this.props.form.values.mdocid, mdoctype: this.props.form.values.mdoctype};
        return docPrepService.loadSectionByTab(metaData, this.props);
    }

    showMessage = (response, resp) => {
        if (response) {
            if (this.props.docStatus === DOC_STATUS.APPROVED) {
                this.props.showMasterDocMessage({
                    isShowMessage: true,
                    masterDocMessage: `Master Document ${this.getMasDocDisplay()} has been approved successfully.`
                });
            } else if (this.props.docStatus === DOC_STATUS.UNLOCKED) {
                this.props.showMasterDocMessage({
                    isShowMessage: true,
                    masterDocMessage: `Master Document ${this.getMasDocDisplay()} has been unlocked successfully.`
                });
            } else if (this.props.docStatus === DOC_STATUS.SUBMITTED) {
                this.props.showMasterDocMessage({
                    isShowMessage: true,
                    masterDocMessage: `Master Document ${this.getMasDocDisplay()} has been submitted successfully.`
                });
            } else {
                this.props.showMasterDocMessage({
                    isShowMessage: true,
                    masterDocMessage: resp.returnMessage,
                });
            }
        }
    }

    isSuccess = (resp, docStatus) => {
        return resp.returnCode === docStatus || resp.returnCode === "SUCCESS";
    }

    updateStatus = (docStatus, masDoc) => {
        this.isLatest(this.props.form.values).then(res=>{
            if(res){
                masterDocService.updateStatus(docStatus, masDoc).then(resp => {
                    if (resp) {
                        if (this.isSuccess(resp, docStatus)) {
                            this.reload().then(response => {
                                this.showMessage(response, resp);
                            })
                        } else {
                            this.props.showMasterDocMessage({
                                isShowMessage: true,
                                masterDocMessage: resp.returnMessage,
                                messageType: 'ERROR'
                            });

                            if (docStatus === DOC_STATUS.SUBMITTED) {
                                masDoc.mdstatushistorylist.shift();
                            }
                        }
                    }else if (docStatus === DOC_STATUS.SUBMITTED){
                        masDoc.mdstatushistorylist.shift();
                    }
                })
            }
        });

    }

    print = (printCmd) => {
        this.saveCurrentChanges(this.openPrintCheck, printCmd);
    }

    openPrint = (printCmd) =>{
        this.isLatest(this.props.form.values).then(res=>{
            if(res){
                this.setState({
                    printDocument: true,
                    printCmd,
                })
            }
        });
    }

    hasBankApprove = (statusList) => {
        return statusList.some(statusObj=>{ return statusObj.status === 'BANK_APPROVED'});
    }

    hasSubmitted = (statusList) => {
        return statusList.some(statusObj=>{ return statusObj.status === 'SUBMITTED'});
    }

    isEDS = (printCmd) => {
        return (printCmd===PRINT_CMD.PRINT_DIRECT
                || printCmd===PRINT_CMD.PRINT_EDS
                || printCmd===PRINT_CMD.PRINT_EDS_EMAIL);
    }

    openPrintCheck = (printCmd) => {
        if(!ModuleService.isEDSWorkflow()
            || [PRINT_CMD.PRINT_DRAFT, PRINT_CMD.PRINT_TRADITIONAL, PRINT_CMD.PRINT_TRANSMIT, PRINT_CMD.PRINT_EMAIL].includes(printCmd) ){
            this.openPrint(printCmd);
        }else{
            const statusList = this.props.form.values.mdstatushistorylist;
            if(statusList?.length > 0){
                const statusDTO = statusList[0];
                if(!this.hasSubmitted(statusList) && statusDTO.status === "APPROVED" && !this.isEDS(printCmd)){
                    this.openPrint(printCmd);
                }else if(['BANK_APPROVED', 'PRINTED', 'PARTIAL'].includes(statusDTO.status) && this.hasBankApprove(statusList) && this.isEDS(printCmd)){
                    this.openPrint(printCmd);
                }else if(statusDTO.status !== 'BANK_APPROVED' && this.isEDS(printCmd)){
                    this.showNotApproveMsg();
                }else{
                    this.showNotApproveMsg();
                }
            }else{
                this.showNotApproveMsg();
            }
        }

    }

    openDocumentPopup = () => {
        this.performInitDocument();
        this.setState({
            openDocument: true,
        });
    }

    isLatest = (masterDoc) => {
        return masterDocService.getMasterDoc(masterDoc.mdocid, masterDoc.mdoctype).then(res=>{
            if(res && res.statusCode === 200){
                const doc = res.data;

                if(doc.status !== masterDoc?.status
                    || doc.lastupdatedate !== masterDoc?.lastupdatedate
                    || doc.lastupdatetime !== masterDoc?.lastupdatetime
                    || doc.dateupdate !== masterDoc?.dateupdate){
                        this.showNotUpdateMsg();
                        return false;
                }
                return true;
            }

            this.showNotUpdateMsg();
            return false;
        })
    }

    showNoEditRight = (title) => {
        this.setState({
            openNotice: true,
            msg: "You do not have edit right to save current record.",
            title: title || "Save",
        });
    }

    isNonEditableStatus = () => {
        const docStatus = this.props.form.values.status;
        return docStatus===DOC_STATUS.APPROVED || docStatus===DOC_STATUS.PARTIAL || docStatus===DOC_STATUS.SUBMITTED
                    || docStatus===DOC_STATUS.PRINTED || docStatus===DOC_STATUS.BANK_APPROVED || docStatus===DOC_STATUS.BANK_REJECTED
    }

    isSuccessResp = (responses) => {
        return responses && responses.statusCode === 200;
    }

    updateMasterDoc = () => {
        docPrepService.performUpdateDocument(this.props).subscribe(responses => {
            if (Array.isArray(responses) && !responses.find(res => res.statusCode !== 200)) {
                const metaData = { mdocid: this.props.form.values.mdocid, mdoctype: this.props.form.values.mdoctype };
                docPrepService.loadSectionByTab(metaData, this.props).then(response => {
                    if (response) {
                        this.showMasterDocMsg();
                    }
                });
            } else if (this.isSuccessResp(responses)) {
                this.props.setMasterDoc(this.props.form.values);
            }
        });
    }

    showSavePopup = () => {
        if (this.props.form.submitSucceeded && this.props.docStatus !== 'isNew') {
            if (!this.user.editright) {
                this.showNoEditRight();
                return;
            }
            Message.confirm(
                'Do you really wish to save?',
                () => this.updateMasterDoc()
            );
        }
    }


    saveMasterDoc = () => {
        if (!this.user.editright) {
            this.showNoEditRight();
            return;
        }
        this.isLatest(this.props.form.values).then(resp=>{
            if(resp){
                if (!this.isNonEditableStatus()) {
                    this.props.onSubmit();
                    setTimeout(()=>this.showSavePopup());
                }else{
                    let msg = 'The current document cannot be saved as it is locked. Please unlock the document before saving it.';
                    if(this.props.form.values.status === DOC_STATUS.SUBMITTED){
                        msg = "Currently the record has already been submitted, it can't be edited until HSBC has approved or rejected it.";
                    }
                    this.props.showMasterDocMessage({
                        isShowMessage: true,
                        masterDocMessage: msg,
                        messageType: 'ERROR'
                    })
                }
            }
        });

    }

    confirmSave = () => {
        if (!this.user.editright) {
            this.props.onDismissReset(null);
            this.props.dismissTargetUrl();
            this.showNoEditRight();
            return;
        }
        setTimeout(() => {
            this.props.onSubmit();
            if (this.props.form.submitSucceeded) {
                docPrepService.performUpdateDocument(this.props).subscribe(responses => {
                    console.log(responses);
                    const currentTab = getCurrentTab();
                    if (Array.isArray(responses)) {
                        if (responses.find(res => res.statusCode !== 200)) {
                            console.log('errror');
                        }
                        else {
                            this.setState({
                                isReset: false,
                            });
                            this.props.history.push(this.state.targetUrl);
                            this.props.setMasterDoc(this.props.form.values);
                            this.props.setPackingList(null);
                            this.props.setBeneficiaryCert(null);
                            this.props.confirmChange(this.state.targetUrl);
                            this.showMasterDocMsg(currentTab);
                        }
                    }
                    else if (this.isSuccessResp(responses)) {
                        this.setState({
                            isReset: false,
                        });
                        this.props.history.push(this.state.targetUrl);
                        this.props.setMasterDoc(this.props.form.values);
                        this.props.setPackingList(null);
                        this.props.setBeneficiaryCert(null);
                        this.props.confirmChange(this.state.targetUrl);
                        this.showMasterDocMsg(currentTab);
                    }
                });
            }
        }, 0);
        this.props.onDismissReset(null);
    }

    getMasDocDisplay = () => {
        if(this.props.form?.values?.mdocdesc){
            return `"${this.props.form?.values?.mdocid}" - "${this.props.form?.values?.mdocdesc}"`;
        }
        return `"${this.props.form?.values?.mdocid}"`;
    }

    showNotUpdateMsg() {
        this.props.showMasterDocMessage({
            isShowMessage: true,
            masterDocMessage: 'This Master Document has been updated by others, please reload this document.',
            messageType: 'ERROR'
        });
    }

    showNotApproveMsg() {
        this.setState({
            openNotice: true,
            msg: "The Master Document is yet to be approved by Bank. Please try again later.",
            title: ""
        });
    }

    saveAs() {
        if (this.state.targetUrl) {
            this.props.history.push(this.state.targetUrl);
            this.props.dismissTargetUrl();
        }
        this.setState({
            open: false
        })
    }

    performInitDocument() {
        const currentTab = getCurrentTab();
        this.props.setMasterDoc(this.props.form.values);
        switch (currentTab) {
            case TABS.MASTERDOC:
                break;
            case TABS.INVOICE:
                break;
            case TABS.PACKINGLIST:
                if (this.props.packingList) {
                    this.props.setPackingList(this.props.packingList.values);
                } else {
                    this.props.setPackingList([]);
                }
                break;
            case TABS.BENECERT:
                if (this.props.beneficiaryCert) {
                    this.props.setBeneficiaryCert(this.props.beneficiaryCert.values);
                } else {
                    this.props.setBeneficiaryCert([]);
                }
                break;
            case TABS.BOE:
                break;
            case TABS.EI:
                break;
            default:
        }
    }

    showMasterDocMsg(previousTab) {
        const desc = this.props.form.values.mdocdesc ? `"${  this.props.form.values.mdocdesc  }"` : '';
        const optionalSymbol = !isBlank(desc) ? '-' : '';
        let prefixMessage = '';
        const currentTab = !isBlank(previousTab) ? previousTab : getCurrentTab();
        switch (currentTab) {
            case TABS.INVOICE:
                prefixMessage = 'Invoice(s) for ';
                break;
            case TABS.PACKINGLIST:
                prefixMessage = 'Packing list(s) for ';
                break;
            case TABS.BENECERT:
                prefixMessage = 'Benecert(s) for ';
                break;
            case TABS.BOE:
                prefixMessage = 'Bill of exchange for ';
                break;
            case TABS.EI:
                prefixMessage = 'Export instruction for ';
                break;
            default:
        }

        this.props.showMasterDocMessage({
            isShowMessage: true,
            masterDocMessage: `${prefixMessage}Master Document "${this.props.form.values.mdocid}" ${optionalSymbol} ${desc} has been saved successfully.`,
        });
        window.scrollTo(0, 0);
    }


    newMasterDoc() {
        Message.confirm(
            'Do you wish to create a new record now?',
            () => {
                masterDocService.initMasterDoc().then(res => {
                    docPrepService.performResetDocument(res);
                    this.props.disableInvoiceAmount(false);
                    this.props.showMasterDocMessage({ isShowMessage: false, masterDocMessage: '' });
                });
            }
        );
    }

    performUpdate(continueAction, para){
        docPrepService.performUpdateDocument(this.props).subscribe(responses => {
            if (Array.isArray(responses)) {
                if (!responses.find(res => res && res.statusCode !== 200)) {
                    this.reload().then(res => {
                        if (res) {
                            continueAction.call(this, para);
                        }
                    });
                }
            }
            else if (this.isSuccessResp(responses)) {
                continueAction.call(this, para);
            }

        });
    }

    confirmSaveCurrent(continueAction, para){
        if (!this.user.editright) {
            this.showNoEditRight();
            return;
        }
        this.props.onSubmit();
        setTimeout(()=>{
            if (this.props.form.submitSucceeded) {
                this.performUpdate(continueAction, para)
            }
        }, 200)
    }

    saveCurrentChanges(continueAction, para) {
        if (docPrepService.noDirty()) {
            continueAction.call(this, para);
        }
        else {
            Message.select(
                'Do you want to save the changes made?',
                () => {
                    this.confirmSaveCurrent(continueAction, para)
                },
                () => {
                    continueAction.call(this, para);
                },
                () => {
                    // to show cancel button
                    console.log('cancel');
                }
            );
        }
    }

    renderPrintMenu() {
        return (
          <AccessControl
            docStatus={this.props.docStatus}
            userPermissions={this.userPermissions}
            button="Print"
          >
            <RightBtn>
              <ButtonList
                docStatus={this.props.docStatus}
                userPermissions={this.userPermissions}
                printChoice={this.state.printChoice}
                id="print"
                options={[
                        'Print',
                        { 'label': 'Print draft', 'onClick': () => { this.print(PRINT_CMD.PRINT_DRAFT); } },
                        { 'label': 'Print for traditional collection', 'onClick': () => { this.print(PRINT_CMD.PRINT_TRADITIONAL); } },
                        { 'label': 'Transmit via email', 'onClick': () => { this.print(PRINT_CMD.PRINT_TRANSMIT); } },
                        { 'label': 'Print and email', 'onClick': () => { this.print(PRINT_CMD.PRINT_EMAIL); } },
                        { 'label': 'Electronic direct send', 'onClick': () => { this.print(PRINT_CMD.PRINT_DIRECT); } },
                        { 'label': 'Reprint EDS document', 'onClick': () => { this.print(PRINT_CMD.PRINT_EDS); } },
                        { 'label': 'Re-send EDS document via email', 'onClick': () => { this.print(PRINT_CMD.PRINT_EDS_EMAIL); } },
                    ]}
              />
            </RightBtn>
          </AccessControl>
        );
    }

    renderFileMenu(docType) {
        return (
          <RightBtn>
            <ButtonList
              docStatus={this.props.docStatus}
              docType={docType}
              userPermissions={this.userPermissions}
              id="fileButtons"
              options={[
                        'File',
                        {
                            'label': 'New', 'onClick': () => {
                                console.log(this.props.docStatus);
                                if (this.props.docStatus === 'isNew') {
                                    this.newMasterDoc();
                                } else {
                                    this.saveCurrentChanges(this.newMasterDoc);
                                }

                            }
                        },
                        {
                            'label': 'Open', 'onClick': () => {
                                this.saveCurrentChanges(this.openDocumentPopup);
                            }
                        },
                        {
                            'label': 'Save as', 'onClick': () => {
                                if (!this.user.editright) {
                                    this.showNoEditRight('Save as');
                                } else {
                                    if (this.props.form.values.mdoctype === 'TM') {
                                        this.props.setMasterDocSubmitType('MD');
                                    }
                                    setTimeout(() => {
                                        this.props.onSubmit();
                                        if (this.props.form.submitSucceeded) {
                                            this.setState({ open: true });
                                        }
                                        this.props.setMasterDocSubmitType(null);
                                    }, 0);
                                }

                            }
                        },
                        {
                            'label': 'Save', 'onClick': () => {
                                this.saveMasterDoc();
                            }
                        },
                        {
                            'label': 'Delete', 'onClick': () => {
                                this.setState({
                                    openDelete: true
                                })
                            }
                        }
                    ]}
            />
          </RightBtn>
        )
    }


    render() {
        const docType = this.props.form?.values?.mdoctype;
        return(
          <>
            <Container id="actionbar">

              {this.props.form?.values?.mdstatushistorylist?.length>0 &&
              <ButtonAccessControl
                docStatus={this.props.docStatus}
                userPermissions={this.userPermissions}
                button="HSBC approval"
                themeColor="primary"
                modal={HSBCApproval}
                modalProps={{
                    statusHistory: this.props.form?.values?.mdstatushistorylist,
                    documentSummary:this.props.form?.values?.otherdocscommentlist || [],
                }}
              />}


              <ButtonAccessControl
                docStatus={this.props.docStatus}
                userPermissions={this.userPermissions}
                button="Wolf scan"
              />


              <ButtonAccessControl
                docStatus={this.props.docStatus}
                userPermissions={this.userPermissions}
                button="Doc image"
                themeColor="primary"
                submodule={ModuleService.getSubModules()}
                modal={DocumentImage}
                modalProps={{imageData: this.state.imageData, mdocid: this.props.form?.values?.mdocid}}
              />


              <AccessControl
                docStatus={this.props.docStatus}
                userPermissions={this.userPermissions}
                docType={docType}
                button="Approval"
              >
                <RightBtn>
                  <ButtonList
                    docStatus={this.props.docStatus}
                    userPermissions={this.userPermissions}
                    docType={docType}
                    printChoice={this.state.printChoice}
                    id="approve"
                    options={[
                                'Approval',
                                {'label': 'Approve', 'onClick' : ()=>{this.saveCurrentChanges(this.approve)}},
                                {'label': 'Unlock', 'onClick' : this.unlock},
                                {'label': 'Submit', 'onClick' : this.toggleOpenSubmit},
                            ]}
                  />
                </RightBtn>
              </AccessControl>

              {this.renderPrintMenu()}

              {this.renderFileMenu(docType)}

            </Container>

            {this.state.open &&
            <SaveAs
              formData={this.props.formData}
              isOpen={this.state.open}
              close={() => {
                  this.setState({open: false});
                  this.props.setShowSaveAs(false);
              }}
              submit={this.saveAs}
              handleInputValue={this.props.handleInputValue}
              setMasterDoc={this.props.setMasterDoc}
              setMasterDocState={this.props.setMasterDocState}
              setPackingList={this.props.setPackingList}
              setBeneficiaryCert={this.props.setBeneficiaryCert}
              disableInvoiceAmount={this.props.disableInvoiceAmount}
            />}

            {this.state.openDocument &&
            <OpenDocument
              isOpen={this.state.openDocument}
              close={() => {this.setState({
                    openDocument: false
                })}}
            />}

            {this.state.printDocument &&
            <PrintDocument
              isOpen={this.state.printDocument}
              printCmd={this.state.printCmd}
              isLFEDS={this.state.isLFEDS}
              close={(msg, isError) => {
                    if(msg && msg.length>0){
                        this.setState({
                            printDocument: false,
                            openNotice: true,
                            msg,
                            title: isError ? "" : "Notice",
                        })
                    }else{
                        this.setState({printDocument: false});
                    }

                }}
              reload={this.reload}
            />}

            <Confirm
              isOpen={this.state.showUpdateStatus}
              close={() => {this.setState({showUpdateStatus: false})}}
              content={this.state.statusMsg}
              confirm={() =>{this.checkAndUpdateStatus(this.state.status)}}
            />

            <MultiChoiceMessage
              isOpen={this.state.isReset}
              close={() => {this.setState({
                                isReset: false
                                    })
                            this.props.onDismissReset(null);
                            this.props.dismissTargetUrl();
                                }}
              confirm={this.confirmSave}
              no={() => {this.setState({
                           isReset: false
                        })
                        console.log(this.state.targetUrl);
                        this.props.history.push(this.state.targetUrl);
                        this.props.setPackingList(null);
                        this.props.setBeneficiaryCert(null);
                        this.props.onDismissReset(null);
                        this.props.dismissTargetUrl();
              }}
              warning
              title="Are you sure?"
              content={`Do you wish to save the master document "${this.props.form?.values?.mdocid}" - "${this.props.form?.values?.mdocdesc}"?`}
            />

            <Msg
              hideExtraButton
              isOpen={this.state.resetMessageShowForDel}
              title="Success"
              content={this.state.messageContent}
              confirm={() => {this.setState({resetMessageShowForDel: false})}}
            />

            {this.state.openDelete &&
            <DeleteDocument
              masterDoc={this.props.form?.values}
              isOpen={this.state.openDelete}
              close={() => {
                    this.setState({
                    openDelete: false
                    });
                }}
              confirmDelete={(deleteAll, formnames) => {
                    let message = `Master Document ${this.getMasDocDisplay()} has been deleted successfully.`;
                    if(formnames){
                        message = `${formnames  } of ${message}`;
                    }
                    this.props.showMasterDocMessage({
                        isShowMessage: true,
                        masterDocMessage: message,
                    });
                    if (deleteAll) {
                        masterDocService.initMasterDoc().then(res => {
                            docPrepService.performResetDocument(res);
                        })
                    } else {
                        this.reload();
                    }
                }}
            />}

            {this.state.openSubmit &&
            <SubmitMasterDoc
              isOpen={this.state.openSubmit}
              close={this.toggleOpenSubmit}
              save={this.submitDoc}
            />}

            {this.state.openNotice &&
            <Notice
              title={this.state.title}
              content={this.state.msg}
              isOpen={this.state.openNotice}
              close={()=>{this.setState({openNotice: false})}}
            />}

            {this.state.openWolfMsg &&
            <WolfMsg
              fields={this.state.wolfMsg}
              isOpen={this.state.openWolfMsg}
              close={()=>{this.setState({openWolfMsg: false})}}
            />}

          </>
        )
    }


}

const mapStateToProps = state => ({
    isReset: state.masterDocAction?.isReset,
    targetUrl: state.masterDocAction?.targetUrl,
    initDoc: state.masterDocAction?.initDoc,
    form: state.form?.masterDoc,
    packingList: state.masterDocAction?.packingList,
    beneficiaryCert: state.masterDocAction.beneficiaryCert,
    docStatus: state.masterDocAction?.masterDocState || 'isNew',
    showSaveAs: state.masterDocAction?.showSaveAs
  })


const mapDispatchToProps = dispatch => {
    return {
        onSubmit: () => dispatch(submit('masterDoc')),
        onDismissReset: () => dispatch(dismissReset({form: 'masterDoc', isReset: false })),
        confirmChange: (targetUrl) => dispatch(confirmChangeRoute({form: 'masterDoc', isReset: false, latestUrl: targetUrl })),
        onChangeLoading: (value) => dispatch(changeLoadingState(value)),
        setMasterDocState: (data) => dispatch(setMasterDocState(data)),
        setMasterDoc: (data) => dispatch(setMasterDoc(data)),
        setPackingList: (data) => dispatch(setPackingList(data)),
        setBeneficiaryCert: (data) => dispatch(setBeneficiaryCert(data)),
        setShowSaveAs: (data) => dispatch(showSaveAs({form: 'masterDoc', showSaveAs: data})),
        showMasterDocMessage: (data) => dispatch(showMasterDocMessage(data)),
        dismissTargetUrl: () => dispatch(dismissTargetUrl()),
        setMasterDocSubmitType: (data) => dispatch(setMasterDocSubmitType(data)),
        disableInvoiceAmount: (data) => dispatch(disableInvoiceAmount(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar)
